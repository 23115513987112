import * as React from "react";
import Layout from "../components/Layout";
import * as Styles from "../styles/layout.module.scss";


const Courses = ({ pageContext }) => {
  
  return (
    <Layout
      title="Kontakt"
      pageContext={pageContext}
    >
      <div className={Styles.singleTopicLayout}>        
        <main>
          Meine Kurse
          
        </main>
      </div> 
    </Layout>
  );
};

export default Courses;
